<script lang="ts">
  import { TextField, Button } from "svelte-materialify";
  import axios from "axios";

  const loginToken = { user: "", password: "" };
  const server = "https://casper-rom-api.azurewebsites.net/";
  let error = false;
  async function login() {
    try {
      const token = await axios.get(
        `${server}api/auth/login/${loginToken.user}/${loginToken.password}`
      );
      localStorage.setItem("oauth", token.data);
      window.location.href = "#/app";
    } catch (e) {
      error = true;
    }
  }
</script>

<style>
  .field {
    margin: 40px;
    /* width: 100%; */
  }
  main {
    text-align: center;
    width: 100vw;
  }
  .form {
    margin-top: 100px;
  }
  .navbar {
    overflow: hidden;
    background-color: #fff;
    /* position: fixed; */
    top: 0;
    width: 100%;
    font-size: 20px;
    text-align: center;
  }
</style>

<main>
  <div class="navbar">
    <div>
      <div style="padding:12px">Casper's rum</div>
    </div>
  </div>
  <div class="form">
    <div class="field">
      <TextField
        {error}
        value={loginToken.user}
        on:input={(e) => (loginToken.user = e.target.value)}>
        Bruger
      </TextField>
    </div>

    <div class="field">
      <TextField
        {error}
        value={loginToken.password}
        on:input={(e) => (loginToken.password = e.target.value)}
        type="password">
        Kodeord
      </TextField>
    </div>
    <div class="field">
      <Button
        on:click={login}
        disabled={!loginToken.user || !loginToken.password}>
        Login
      </Button>
    </div>

  </div>
</main>
