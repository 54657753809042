<script>
  import App from "./App.svelte";
  import Edit from "./Edit.svelte";
  import Login from "./Login.svelte";
  import Router from "svelte-spa-router";
  import { MaterialApp } from "svelte-materialify";
  import jwt_decode from "jwt-decode";
  function isValid() {
    try {
      const token = localStorage.getItem("oauth");
      if (token) {
        var decoded = jwt_decode(token);
        return !(Date.now() >= decoded.exp * 1000);
      }
    } catch (e) {
      return false;
    }
  }
  // const logedIn = localStorage.getItem("oaut2h");
  // console.log(logedIn);
  const auth = localStorage.getItem("oauth");
  const routes = {
    "/edit/:id/:name": Edit,
    "/app": App,
    "*": isValid() ? App : Login,
  };
  let theme = "light";
</script>

<MaterialApp {theme}>
  <Router {routes} />
</MaterialApp>

<style>
</style>
