<script lang="ts">
  import Icon from "svelte-awesome";
  import type { IRom } from "./IRom";
  import { faStar as regStar } from "@fortawesome/free-regular-svg-icons";
  import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";

  export let list: IRom[] = [];
  function handleClick(id: string, name: string) {
    console.log(id);
    window.location.href = "#/edit/" + id + "/" + name;
  }
</script>

<style>
  .star {
    color: #ffb300;
  }
  .container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc !important;
    flex-flow: row;
    padding: 8px;
  }
  .title {
    font-size: 18px;
    margin-top: 6px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

{#each list as l, i}
  <div
    class="container"
    on:click={(e) => {
      handleClick(l.id, l.name);
    }}>
    <div class="star">
      <Icon
        data={l.rumIsRatedByMe ? solidStar : regStar}
        scale={2}
        style="margin-right:10px" />
    </div>
    <div class="title">{l.name}!</div>
  </div>
{/each}
